<template>
    <v-container
            id="regular-tables"
            fluid
            tag="section"
    >
        <v-row>
            <v-col
                    cols="12"
                    lg="8"
            >
                <base-material-card
                        class="px-5 py-3"
                >
                    <template v-slot:heading>
                        <div class="display-2 font-weight-light">
                            Daftar Supplier
                        </div>

                        <div class="subtitle-1 font-weight-light">
                        </div>
                    </template>
                    <v-row>
                        <v-col cols="12"
                               lg="6">
                            <v-text-field
                                    v-model="search"
                                    label="Search"
                                    single-line

                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <div class="py-1"/>
                    <v-data-table
                            :headers="headers"
                            :items="items"
                            :search="search"
                    >
                        <template v-slot:item.action="{ item }">
                            <v-icon class="mx-1" @click="edit(item)">
                                mdi-pencil
                            </v-icon>
                            <v-icon
                                    color="error"
                                    class="mx-1"
                                    @click="modalHapus(item)"
                            >
                                mdi-close
                            </v-icon>
                        </template>

                    </v-data-table>
                    <v-dialog v-model="dialogDelete" persistent max-width="290">
                        <v-card>
                            <v-card-title class="headline">Hapus Supplier</v-card-title>
                            <v-card-text>
                                Apa anda yakin ingin menghapus data ini?
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="green darken-1" text @click="dialogDelete = false">Tidak</v-btn>
                                <v-btn color="green darken-1" text @click="hapus()">Ya</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </base-material-card>
            </v-col>
            <v-col
                    cols="12"
                    lg="4"
            >
                <base-material-card>
                    <template v-slot:heading>
                        <div class="display-2 font-weight-light">
                            Form Supplier
                        </div>

                        <div class="subtitle-1 font-weight-light">
                            Tambah / Ubah data Supplier
                        </div>
                    </template>

                    <v-form ref="form">
                        <v-container class="py-0">
                            <v-row>
                                <input type="hidden" name="id" id="id" v-model="idSupplier"/>

                                <v-text-field
                                        class="purple-input"
                                        label="Nama"
                                        v-model="namaSupplier"
                                        :rules="[rules.required]"
                                />
                            </v-row>
                            <v-row>
                                <v-textarea
                                        label="Alamat"
                                        class="purple-input"
                                        v-model="alamatSupplier"
                                        rows="3"
                                        :rules="[rules.required]"
                                />
                            </v-row>
                            <v-row>
                                <v-text-field
                                        class="purple-input"
                                        label="Telp"
                                        v-model="telpSupplier"
                                        :rules="[rules.required]"
                                />
                            </v-row>
                            <v-row>

                                <v-col
                                        cols="12"
                                        class="text-right"
                                >
                                    <v-row justify="end">
                                        <v-col cols="6" md="4">
                                            <v-btn
                                                    color="warning"
                                                    class="mr-0"
                                                    @click="batal"
                                            >
                                                Batal
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="6" md="4">
                                            <v-btn
                                                    color="success"
                                                    class="mr-0"
                                                    @click="simpan"
                                            >
                                                Simpan
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </base-material-card>
                <v-dialog v-model="dialog" persistent max-width="290">
                    <v-card>
                        <v-card-text class="text-center">
                            Loading
                            <v-spacer></v-spacer>
                            <v-progress-linear
                                    indeterminate
                                    rounded
                                    color="yellow darken-2"
                            ></v-progress-linear>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-snackbar
                        v-model="snackbar"
                        :color="color"
                        :top="true"
                >
                    <span>{{message}}</span>
                    <v-btn
                            dark
                            icon
                            @click="snackbar = false"
                    >
                        <v-icon>
                            $vuetify.icons.cancel
                        </v-icon>
                    </v-btn>
                </v-snackbar>
            </v-col>
        </v-row>
        <div class="py-3"/>


    </v-container>
</template>
<script>
    import {ApiUrl} from '../../../../config/constant'
    import axios from 'axios'
    import {
        mapState,
    } from 'vuex'

    export default {
        name: 'IndexSuppliers',
        data() {
            return {
                snackbar: false,
                color: 'success',
                message: '',
                dialog: false,
                dialogDelete: false,
                itemDelete: null,
                namaSupplier: '',
                alamatSupplier: '',
                telpSupplier: '',
                idSupplier: '',
                search: '',
                rules: {
                    required: value => !!value || 'Required.',
                },
                headers: [
                    {
                        sortable: false,
                        text: 'No',
                        value: 'no',
                        align: 'center',
                        width: 50,
                    },
                    {
                        sortable: true,
                        text: 'Nama',
                        filterable: true,
                        value: 'name',
                        width: 100,
                    },
                    {
                        sortable: true,
                        text: 'Alamat',
                        filterable: true,
                        value: 'address',
                        align: 'start',
                        width: 200,
                    },
                    {
                        sortable: true,
                        text: 'Telp',
                        filterable: true,
                        value: 'telp',
                        align: 'start',
                        width: 200,
                    },
                    {
                        sortable: false,
                        text: 'Aksi',
                        value: 'action',
                        align: 'center',
                        width: 70
                    },
                ],
                items: [],
            }
        },
        computed: {
            ...mapState(['currentUser']),
        },
        mounted: function () {
            this.ambilData()
        },
        methods: {
            edit: function (item) {
                this.idSupplier = item.id
                this.namaSupplier = item.name
                this.alamatSupplier = item.address
                this.telpSupplier = item.telp
            },
            batal: function () {
                this.idSupplier = ''
                this.namaSupplier = ''
                this.alamatSupplier = ''
                this.telpSupplier = ''
            },
            modalHapus: function (item) {
                this.dialogDelete = true
                this.itemDelete = item
            },
            hapus: function () {
                this.dialogDelete = false
                let id = this.itemDelete.id
                let token = this.currentUser.token;
                let header = {
                    'Authorization': 'Bearer ' + token,
                    'Content-type': 'application/json'
                }
                axios
                ({method: 'delete', url: ApiUrl + 'suppliers/hapus/' + id, headers: header})
                    .then(response => {
                        console.log(response)

                        if (response.data.success) {
                            this.snackbar = true
                            this.color = 'success'
                            this.message = 'Berhasil hapus data                    '
                            this.ambilData()
                        }

                    })
                    .catch(error => {
                        console.log(error)
                        alert(error)
                    })
            },
            simpan: function () {
                if (this.namaSupplier && this.alamatSupplier !== '' && this.telpSupplier !== '') {
                    let data = {
                        name: this.namaSupplier,
                        address: this.alamatSupplier,
                        telp: this.telpSupplier,
                        id: this.idSupplier,
                    }
                    let token = this.currentUser.token;
                    let header = {
                        'Authorization': 'Bearer ' + token,
                        'Content-type': 'application/json'
                    }
                    this.dialog = true
                    axios
                    ({method: 'post', url: ApiUrl + 'suppliers/simpan', data: data, headers: header})
                        .then(response => {
                            console.log(response)
                            this.dialog = false
                            if (response.data.success) {
                                this.snackbar = true
                                this.color = 'success'
                                this.message = 'Berhasil simpan'
                                this.idSupplier = ''
                                this.namaSupplier = ''
                                this.alamatSupplier = ''
                                this.telpSupplier = ''
                                this.$refs.form.resetValidation()
                                this.ambilData()
                            } else {
                                this.snackbar = true
                                this.color = 'error'
                                this.message = 'Gagal simpan'
                            }
                        })
                        .catch(error => {
                            this.dialog = false
                            console.log(error)
                        })
                } else {
                    this.snackbar = true
                    this.color = 'error'
                    this.message = 'Nama, alamat dan telp harus diisi'
                }
            },
            ambilData: function (e) {
                let token = this.currentUser.token;
                let header = {
                    'Authorization': 'Bearer ' + token,
                    'Content-type': 'application/json'
                }
                axios
                ({method: 'get', url: ApiUrl + 'suppliers', headers: header})
                    .then(response => {
                        console.log(response)
//                        this.dialog = false
                        if (response.data.success) {
                            this.items = response.data.data
                        }
                    })
                    .catch(error => (
                        console.log(error)
                    ))
            }
        }
    }
</script>